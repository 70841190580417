import React from "react"
import { withRouter } from "react-router-dom"
import { ReportViewer } from "../components/powerbi/ReportViewer"

interface DashboardState {
  isLoading: boolean
  dashboards: string[] | null
  isGlobalDashboard: boolean
  error: string | undefined
  currentDashboard: string | null
}

class Dashboard extends React.Component<any, DashboardState> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: true,
      dashboards: null,
      isGlobalDashboard: props.location.pathname === "/dashboard",
      error: undefined,
      currentDashboard: null,
    }
  }

  render() {
    const siteId = this.props.match.params.id
    return (
      <div style={{ height: "100%" }}>
        <ReportViewer
          type={this.state.isGlobalDashboard ? "perimetre" : "site"}
          resourceId={siteId}
        />
      </div>
    )
  }
}

export default withRouter(Dashboard)
