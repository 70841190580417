import { useEffect, useState, useMemo } from "react"
import Breadcrumb from "../components/common/Breadcrumb"
import { Auth, API } from "aws-amplify"
import { Container, Button, Spinner } from "react-bootstrap"
import MarkdownIt from "markdown-it"

const ChangeLog = (path: string) => {
  const [sending, setSending] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [story, setStory] = useState("")
  const [admin, setAdmin] = useState(false)
  const markdown = useMemo(() => new MarkdownIt().render(story), [story])

  useEffect(() => {
    API.get("main", "/changelog", {}).then((journal: string) => {
      setStory(journal)
    })
    Auth.currentSession().then((info) => {
      setAdmin(
        info.getAccessToken().payload["cognito:groups"].includes("role:admin")
      )
    })
  }, [])
  const base_path = "/changelog"
  const crumbs = [{ name: "Change Log", path: base_path }]

  const post = () => {
    setSending(true)
    return API.post("main", "changelog", {
      body: story,
    }).then(() => setSending(false))
  }

  const handleClick = () => {
    if (editMode) {
      return post().then(() => setEditMode(false))
    } else {
      setEditMode(true)
    }
  }

  const downloadUserGuide = () => {
    API.get("main", "/userguide", {
      responseType: "blob",
    }).then((res) => {
      const file = new Blob([res], { type: "application/pdf" })
      const fileURL = URL.createObjectURL(file)
      const pdfWindow = window.open()
      pdfWindow!.location.href = fileURL
    })
  }
  return (
    <>
      <Container fluid style={{ margin: "14px 0px" }}>
        <Breadcrumb crumbs={crumbs}></Breadcrumb>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "-39px",
          }}
        >
          <Button onClick={() => downloadUserGuide()} variant="secondary">
            Guide utilisateur
          </Button>
        </div>
      </Container>
      <form>
        {editMode ? (
          <textarea
            value={story}
            onChange={(e) => setStory(e.target.value)}
            id="story"
            name="story"
            style={{ width: "97%", marginLeft: "15px", marginRight: "15px" }}
            rows={20}
          />
        ) : (
          <div
            style={{
              width: "97%",
              marginLeft: "15px",
              marginRight: "15px",
            }}
            dangerouslySetInnerHTML={{ __html: markdown }}
          /> // for you see markdown directly in HTML
        )}
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {admin && (
            <Button onClick={handleClick} variant="secondary">
              {sending ? (
                <Spinner animation="border" size="sm" />
              ) : editMode ? (
                "Enregistrer"
              ) : (
                "Editer"
              )}
            </Button>
          )}
        </div>
      </form>
    </>
  )
}

export default ChangeLog
