const Loader = (props) => {
  return (
    <div
      className={`d-flex w-100 justify-content-center align-items-center ${
        props.fullscreen ? "h-100" : ""
      }`}
    >
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default Loader
