/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint jsx-a11y/anchor-has-content: 0 */

import Amplify from "aws-amplify"
import React from "react"
import { withRouter } from "react-router-dom"
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap"
import PageTitle from "../components/common/PageTitle"
import Loader from "../components/common/Loader"
import Breadcrumb, {
  computeCrumbsFromProps,
} from "../components/common/Breadcrumb"
import Errors from "./Errors"
import BuildingResources from "../components/BuildingResources"
import moment from "moment"

class Building extends React.Component {
  constructor(props) {
    super(props)

    // In the case we are coming from the view 'buildings", data are already
    // available.
    const fromPreviousView = this.props.location.state

    if (fromPreviousView) {
      this.state = {
        isLoading: false,
        error: false,
        building: fromPreviousView,
      }
      return
    }

    this.state = {
      isLoading: true,
      error: false,
      building: undefined,
    }
  }

  loadBuildingData() {
    const id = this.props.match.params.id
    Amplify.API.get("main", `building/${id}`)
      .then((building) => {
        if (!building) {
          this.setState({
            isLoading: false,
            error: true,
          })
          return
        }

        this.setState({
          isLoading: false,
          building,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: true,
        })
      })
  }

  componentDidUpdate(prevProps) {
    const previousId = prevProps.match.params.id
    const currentId = this.props.match.params.id
    if (previousId !== currentId) {
      this.setState({
        isLoading: true,
        building: null,
        error: false,
      })
      this.loadBuildingData()
    }
  }

  componentDidMount() {
    if (this.state.building) return

    this.loadBuildingData()
  }

  render(props) {
    if (this.state.isLoading) {
      return <Loader fullscreen></Loader>
    }

    const building = this.state.building

    if (this.state.error || !building) {
      return <Errors></Errors>
    }

    const { lon, lat, address, type } = building

    // set document title
    document.title = "setec - " + building.name

    const crumbs = computeCrumbsFromProps(this.props, {
      ":building-name": building.name,
    })

    return (
      <Container
        fluid
        style={{
          padding: "0",
          marginBottom: "15px",
          flex: "1",
          display: "flex",
          flexDirection: "column",
          maxWidth: "1400px",
        }}
      >
        <Container fluid style={{ margin: "14px 0px" }}>
          <Breadcrumb crumbs={crumbs}></Breadcrumb>
        </Container>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header pb-md-3 pb-sm-0">
            <PageTitle
              title={building.name}
              subtitle="Actif"
              className="text-sm-left"
            />
            <div className="ml-0 ml-sm-auto d-flex align-items-center mt-md-0 mt-3">
              <strong className="text-uppercase page-subtitle mr-3 d-none d-sm-none d-md-block">
                Ressources :
              </strong>
              <BuildingResources site={building} />
            </div>
          </Row>
          <Row className="building-row">
            <Col xl="3" lg="4" md="5" sm="0" xs="0">
              <Card className="h-100" style={{ overflow: "hidden" }}>
                <Photo imgUrl={building.imgUrlThumbnail}></Photo>
              </Card>
            </Col>
            <Col xl="6" lg="8" md="7" sm="12" xs="12">
              <AttributesTable building={building}></AttributesTable>
            </Col>
            <Col xl="3" lg="4" md="5" sm="6" xs="12">
              {type === "TERTIARY" && <Connectivity building={building} />}
            </Col>
            {/* Map */}
            {lon && lat ? (
              <Col className="d-xs-none d-sm-block" xl="3" lg="4" md="7" sm="6">
                <Map lon={lon} lat={lat} address={address}></Map>
              </Col>
            ) : null}
          </Row>
        </Container>
      </Container>
    )
  }
}

const Photo = ({ imgUrl }) => {
  return (
    <div
      className="w-100 h-100"
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  )
}

const AttributesTable = ({ building }) => {
  const attributes = [
    {
      label: "Nom de l'actif",
      key: "name",
    },
    {
      label: "Type",
      value: building.type === "TERTIARY" && "Tertiaire",
    },
    {
      label: "Ville",
      key: "city",
    },
    {
      label: "Adresse",
      key: "address",
    },
    {
      label: "Surface",
      value: building.area && building.area.toLocaleString() + " m²",
      // TODO check building.areaUnit to verify unit
    },
    {
      label: "Mainteneur",
      key: "facilityManager",
    },
  ]

  return (
    <Card style={{ minHeight: "100%" }}>
      <Card.Header>
        <h6 className="m-0">Infos</h6>
      </Card.Header>
      <Card.Body className="p-0">
        <table
          className="table mb-0"
          style={{ borderBottom: "1px solid #dee2e6" }}
        >
          <tbody>
            {attributes.map(({ label, key, value }, i) => {
              return (
                <tr key={i}>
                  <td>
                    <strong>{label}</strong>
                  </td>
                  <td>{value || building[key] || "-"}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  )
}

const Map = ({ lat, lon, address }) => {
  if (!lat || !lon) return null

  const height = 390
  const width = 500
  const url = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l-1+18833d(${lon},${lat})/${lon},${lat},14,0/${width}x${height}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
  const gmaps = `https://www.google.com/maps/search/?api=1&query=${encodeURI(
    address
  )}`

  return (
    <div className="building-map">
      <a
        href={gmaps}
        target="_blank"
        aria-hidden
        rel="noreferrer"
        className="stretched-link"
      ></a>
      <div className="map">
        <img alt="map" src={url}></img>
      </div>
    </div>
  )
}

const Connectivity = ({ building }) => {
  const connectivity = building.connectivity || []
  const gmao = connectivity.find((e) => e.source === "gmao")
  const advizeo = connectivity.find((e) => e.source === "advizeo")
  const kone = connectivity.find((e) => e.source === "kone")
  const gtb = connectivity.find((e) => e.source === "gtb")
  const ged = building.ged
  return (
    <Card
      style={{ overflow: "hidden", minHeight: "100%" }}
      className="card-small"
    >
      <Card.Header className="border-bottom">
        <h6 className="m-0">Connectivité</h6>
      </Card.Header>

      <Card.Body className="p-0">
        <ListGroup variant="flush">
          {[
            {
              source: "gmao",
              label: "GMAO",
              icon: "handyman",
              value: gmao ? (
                <strong className="text-success">Activé</strong>
              ) : (
                <span className="text-secondary"> Non</span>
              ),
              info:
                gmao &&
                `Dernière activité ${moment
                  .unix(gmao.last_activity)
                  .fromNow()}`,
            },
            {
              source: "advizeo",
              label: "IOT - advizeo",
              icon: "contactless",
              value: advizeo ? (
                <span>
                  <strong className="text-success">Activé</strong>
                </span>
              ) : (
                <span className="text-secondary"> Non</span>
              ),
              info:
                advizeo &&
                `Dernière activité ${moment
                  .unix(advizeo.last_activity)
                  .fromNow()}`,
            },
            {
              source: "kone",
              label: "IOT - Ascenseurs",
              icon: "contactless",
              value: kone ? (
                <strong className="text-success">Activé</strong>
              ) : (
                <span className="text-secondary"> Non</span>
              ),
              info:
                kone &&
                `Dernière activité ${moment
                  .unix(kone.last_activity)
                  .fromNow()}`,
            },
            {
              source: "gtb",
              label: "GTB",
              icon: "build",
              value: gtb ? (
                <strong className="text-success">Activé</strong>
              ) : (
                <span className="text-secondary"> Non</span>
              ),
              info:
                gtb &&
                `Dernière activité ${moment.unix(gtb.last_activity).fromNow()}`,
            },
            {
              source: "doc",
              label: "Gestion documentaire",
              icon: "description",
              value: ged ? (
                <strong className="text-success">Oui</strong>
              ) : (
                <span className="text-secondary"> Non</span>
              ),
            },
          ].map((e, key) => (
            <ListGroup.Item className="d-flex flex-column" key={key}>
              <div>
                <i className="material-icons mr-1">{e.icon}</i>
                <strong className="mr-1">{e.label}: </strong>
                <span className="text-secondary">{e.value}</span>
              </div>
              {e.info && (
                <i style={{ fontSize: "0.85em" }} className="d-inline-block">
                  {e.info}
                </i>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  )
}

export default withRouter(Building)
