import React from "react"

export default function Spinner(props) {
  return (
    <div
      class={
        "spinner-border" + (props.size === "sm" ? " spinner-border-sm" : "")
      }
      style={props.style}
    />
  )
}
