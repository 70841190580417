import { Button, Form } from "react-bootstrap"

import React, { Component } from "react"

export class LeafletMarkerForm extends Component {
  render() {
    return (
      <Form className={"marker-form"}>
        <Form.Group controlId="leafletViewId">
          <label htmlFor="leafletViewId">Identifiant Photosphère</label>
          <Form.Control
            id={"leafletViewId"}
            onChange={this.props.handleChangeMarkerEditSave}
            value={this.props.photosphereId}
          />
        </Form.Group>
        <div className={"edit-marker-menu"}>
          <Button
            variant="secondary"
            onClick={this.props.handleClickMarkerEditCancel}
          >
            Annuler
          </Button>
          <Button
            variant="danger"
            onClick={this.props.handleClickMarkerEditDelete}
          >
            Supprimer
          </Button>
          <Button
            variant="primary"
            onClick={this.props.handleClickMarkerEditSave}
          >
            Sauvegarder
          </Button>
        </div>
      </Form>
    )
  }
}
