/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react"
import { Container, Row, Col, Card, Badge } from "react-bootstrap"
import Amplify from "aws-amplify"
import { Link } from "react-router-dom"

import PageTitle from "../components/common/PageTitle"
import ServiceBadge from "../components/common/ServiceBadge"
import Loader from "../components/common/Loader"

export default class Buildings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      error: false,
      buildings: [],
    }
  }

  componentDidMount() {
    Amplify.API.get("main", "buildings")
      .then((body) => {
        const buildings = body && body.buildings

        if (!buildings || !Array.isArray(buildings)) {
          this.setState({
            isLoading: false,
            error: true,
          })
          return
        }

        this.setState({
          isLoading: false,
          buildings,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: true,
        })
      })
  }

  render() {
    const { isLoading, buildings, error } = this.state

    let content

    if (isLoading) {
      content = <Loader></Loader>
    } else if (error) {
      content = (
        <div className="d-flex w-100 justify-content-center">
          Une erreur s'est produite, veuillez contacter l'administrateur.
        </div>
      )
    } else if (!buildings || !buildings.length) {
      content = (
        <div className="d-flex w-100 justify-content-center">
          Aucune donnée disponible
        </div>
      )
    } else {
      content = (
        <Container fluid>
          <Row noGutters className="py-2">
            <h5>Bâtiments tertiaires</h5>
          </Row>
          <Row>
            <BuildingList buildings={buildings} type="TERTIARY"></BuildingList>
          </Row>
          <Row noGutters className="py-2">
            <h5>Bâtiments résidentiels</h5>
          </Row>
          <Row>
            <BuildingList
              buildings={buildings}
              type="RESIDENTIAL"
            ></BuildingList>
          </Row>
        </Container>
      )
    }

    return (
      <Container
        fluid
        className="main-content-container px-4"
        style={{ maxWidth: "1400px" }}
      >
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Actifs numérisés"
            subtitle="Vos actifs"
            className="text-sm-left"
          />
        </Row>
        <Row>{content}</Row>
      </Container>
    )
  }
}

function BuildingList(props) {
  const { buildings, type } = props

  return buildings
    .filter((building) => building.type === type)
    .map((building, idx) => (
      <Col lg="3" md="6" sm="6" className="mb-4" key={idx}>
        <Building building={building}></Building>
      </Col>
    ))
}

function Building(props) {
  const {
    imgUrlThumbnail,
    name,
    address,
    city,
    id,
    forge_model_urn,
    photospheres,
    ged,
    bi_report,
  } = props.building
  return (
    <Card className="card-post card-post--1 h-100">
      <div
        className="card-post__image"
        style={{ backgroundImage: `url(${imgUrlThumbnail})` }}
      >
        <BuildingBadge city={city}></BuildingBadge>
      </div>
      <Card.Body className="d-flex flex-column justify-content-between">
        <Link
          to={{ pathname: `/building/${id}`, state: props.building }}
          className="text-fiord-blue stretched-link"
          style={{ textDecoration: "none" }}
        >
          <h5 className="card-title">{name}</h5>
        </Link>
        <p className="card-text d-inline-block mb-3">{address}</p>
        <div className="d-flex flex-wrap">
          {bi_report ? (
            <Link
              style={{ zIndex: 1 }}
              to={{
                pathname: `/building/${id}/dashboard`,
                state: props.building,
              }}
              className="building-resource"
            >
              <span className="mr-1 mb-2">
                <ServiceBadge value="BI" />
              </span>
            </Link>
          ) : null}
          {forge_model_urn ? (
            <Link
              style={{ zIndex: 1 }}
              to={{
                pathname: `/building/${id}/3d-viewer`,
                state: props.building,
              }}
              className="building-resource"
            >
              <span className="mr-1 mb-2">
                <ServiceBadge value="BIM" />
              </span>
            </Link>
          ) : null}
          {ged ? (
            <a
              style={{ zIndex: 1 }}
              href={`/documents/${ged}`}
              rel="noreferrer"
            >
              <span className="mr-1 mb-2">
                <ServiceBadge value="GED" />
              </span>
            </a>
          ) : null}
          {photospheres ? (
            <a
              style={{ zIndex: 1 }}
              href={`/building/${id}/photospheres`}
              rel="noreferrer"
            >
              <span className="mr-1 mb-2">
                <ServiceBadge value="360°" />
              </span>
            </a>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  )
}

function BuildingBadge(props) {
  const { city } = props
  const label = city
  const color = "dark"
  return (
    <Badge pill className={`card-post__category bg-${color}`}>
      {label}
    </Badge>
  )
}
