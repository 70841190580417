import { Button } from "react-bootstrap"
import Spinner from "./Spinner"

export default function SendingButton(props) {
  return (
    <Button
      variant={props.variant}
      disabled={props.sending}
      onClick={props.onClick}
    >
      {props.sending && (
        <Spinner animation="border" size="sm" style={{ marginRight: "6px" }} />
      )}
      {props.value || props.children}
    </Button>
  )
}
