import { API } from "aws-amplify"

export function download(
  fileId: string,
  filename: string,
  useGraphApi = false
) {
  const url = useGraphApi
    ? `documents/graph/file/${fileId}`
    : `documents/file/${fileId}`
  API.get("main", url, {})
    .then((response) => {
      const { download_url } = response
      const a = document.createElement("a")
      a.href = download_url
      a.download = filename
      a.target = "_blank"
      a.rel = "noopener noreferrer"
      a.click()
    })
    .catch((error) => {
      console.error(error)
    })
}
