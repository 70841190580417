import { API } from "aws-amplify"
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { Permission } from "../types/Permission"
import { Site } from "../types/Site"

interface UserContextState {
  sites: Site[]
  isLoading: boolean
  checkPermission: (permission: Permission) => boolean
  dashboard: boolean
}

export const UserContext = createContext<UserContextState>({
  sites: [],
  isLoading: true,
  checkPermission: () => false,
  dashboard: false,
})

export const useUserContext = () => useContext(UserContext)

export const UserContextProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [permissions, setPermissions] = useState<Permission[]>([])
  const [sites, setSites] = useState<Site[]>([])
  const [dashboard, setDashboard] = useState(false)

  const checkPermission = useCallback(
    (permission: Permission) => {
      return permissions.includes("all") || permissions.includes(permission)
    },
    [permissions]
  )

  useEffect(() => {
    API.get("main", "/summary", {})
      .then((response) => {
        setPermissions(response.permissions)
        setSites(response.buildings)
        setDashboard(response.dashboard)
      })
      .catch((err) => {
        console.error("error fetching user permissions", err)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <UserContext.Provider
      value={{ isLoading, checkPermission, sites, dashboard }}
    >
      {children}
    </UserContext.Provider>
  )
}
