import { FC } from "react"
import { Link } from "react-router-dom"
import { Site } from "../types/Site"

type ResourcesProps = {
  site: Site
}

const Resources: FC<ResourcesProps> = ({ site }) => {
  const { forge_model_urn, id, photospheres, bi_report, resources } = site

  return (
    <div className="d-flex flex building-resources">
      {/* Photosphere */}
      {photospheres && (
        <Link
          to={{ pathname: `/building/${id}/photospheres`, state: site }}
          className="building-resource"
        >
          <i className="material-icons mr-1">360</i> Locaux techniques
        </Link>
      )}
      {/* BIM */}
      {forge_model_urn && (
        <Link
          to={{ pathname: `/building/${id}/3d-viewer`, state: site }}
          className="building-resource"
        >
          <i className="material-icons mr-1">domain</i>Maquette 3D BIM
        </Link>
      )}
      {/* BI dashboard */}
      {bi_report && (
        <Link
          to={{ pathname: `/building/${id}/dashboard` }}
          className="building-resource"
        >
          <i className="material-icons mr-1">leaderboard</i>Carnet de santé
        </Link>
      )}
      {resources &&
        resources.map((r) => (
          <a
            href={r.url}
            key={r.url}
            target="_blank"
            rel="noreferrer"
            className="building-resource"
          >
            <i className="material-icons mr-1">{r.icon}</i>
            {r.label}
          </a>
        ))}
    </div>
  )
}

export default Resources
