import PhotoSphereViewer from "../components/common/PhotosphereViewer"
import SendingButton from "../components/common/SendingButton"
import React, { useState } from "react"
import { Redirect, withRouter, Link } from "react-router-dom"
import Amplify, { Auth } from "aws-amplify"
import Loader from "../components/common/Loader"
import Breadcrumb, {
  computeCrumbsFromProps,
} from "../components/common/Breadcrumb"
import Errors from "./Errors"
import {
  Badge,
  Col,
  Container,
  Card,
  ListGroup,
  Row,
  Button,
  Form,
  Modal,
} from "react-bootstrap"
import { LeafletMap } from "../components/viewer-2d/LeafletMap"
import ModelInfo from "../components/model-info/ModelInfo"

export class Photospheres extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      error: false,
      admin: false,
      photospheres: undefined,
      buildingName: undefined,
      current_items: undefined,
      current_item_index: undefined,
      current_equipment_id: undefined,
      initDownload: false,
      visiblePhotosphereId: null,
      width: null,
      height: null,
      editMode: false,
    }
  }

  componentDidMount() {
    const parent = this

    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)

    Auth.currentSession().then((info) => {
      this.setState({
        admin: info
          .getAccessToken()
          .payload["cognito:groups"].includes("role:admin"),
      })
    })

    eventBus.on("downloadInit", () => {
      parent.setState({
        initDownload: true,
      })
    })

    eventBus.on("downloadReady", () => {
      parent.setState({
        initDownload: false,
      })
    })

    Amplify.API.get(
      "main",
      `building/${this.props.match.params.id}/photospheres`
    )
      .then((response) => {
        const photospheres = response && response.photospheres
        const building = response && response.building

        if (!photospheres || !building) {
          this.setState({
            isLoading: false,
            error: true,
          })
          return
        }

        const buildingName = response.buildingName

        this.setState({
          isLoading: false,
          photospheres,
          buildingName,
          building: building,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: true,
        })
      })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  render() {
    if (this.state.isLoading) return <Loader fullscreen />

    if (this.state.error) {
      return <Errors />
    }

    const buildingId = this.props.match.params.id
    const buildingName = this.state.buildingName || buildingId
    const photosphereId = this.props.match.params.photosphere_id
    const viewId = this.props.match.params.view_id
    const { levels, defaultLevel } = this.state.building

    if (photosphereId === undefined) {
      const crumbs = computeCrumbsFromProps(this.props, {
        ":building-name": buildingName,
      })
      return (
        <div
          style={{
            width: "100%",
            flex: "1",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container fluid style={{ margin: "14px 0px" }}>
            <Breadcrumb crumbs={crumbs} />
          </Container>
          <Container style={{ flex: 1, position: "relative" }} fluid>
            <div className="sm-none huge-map-obj-container">
              <LeafletMap
                photosphereId={photosphereId}
                buildingId={buildingId}
                defaultLevel={defaultLevel}
                floors={levels}
                editEnabled={this.state.admin}
                className={"huge"}
                handleMarkerClick={this.updateVisiblePhotosphere.bind(this)}
                parentEditHandler={this.resetVisiblePhotosphere.bind(this)}
              />
            </div>
            <div className={"photospheres-container"}>
              <Container fluid>
                <Row>
                  {Object.values(this.state.photospheres).map(
                    (photosphere, key) => {
                      if (
                        this.state.visiblePhotosphereId == null ||
                        this.state.visiblePhotosphereId ===
                          photosphere.photosphere_id
                      ) {
                        return (
                          <Col lg="12" className="mb-4" key={key}>
                            <Card className="card-post card-small card-post--1 h-100">
                              <div
                                className="card-post__image"
                                style={
                                  photosphere.thumbnail
                                    ? {
                                        backgroundImage: `url(${photosphere.thumbnail})`,
                                        backgroundSize: "auto",
                                      }
                                    : { background: "#e9ecef" }
                                }
                              >
                                <Badge
                                  pill
                                  className={`card-post__category bg-dark`}
                                >
                                  {photosphere.level}
                                </Badge>
                              </div>
                              <Card.Body className="d-flex flex-column justify-content-between">
                                <Link
                                  to={{
                                    pathname: `/building/${buildingId}/photospheres/${photosphere.photosphere_id}/1`,
                                    state: this.state,
                                  }}
                                  className="text-fiord-blue stretched-link"
                                  style={{ textDecoration: "none" }}
                                >
                                  <h5 className="card-title">
                                    {photosphere.name}
                                  </h5>
                                </Link>
                                <EditForm
                                  state={this.state}
                                  params={this.props.match.params}
                                  photosphereId={photosphere.photosphere_id}
                                />
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      } else {
                        return null
                      }
                    }
                  )}
                </Row>
              </Container>
            </div>
          </Container>
        </div>
      )
    }

    if (viewId === undefined) {
      return (
        <Redirect
          to={{
            pathname: `/building/${buildingId}/photospheres/${photosphereId}/1`,
          }}
        />
      )
    }

    const current_photosphere = this.state.photospheres[photosphereId]
    const current_view = current_photosphere.views[viewId]
    if (current_view === undefined) {
      return (
        <Redirect
          to={{
            pathname: `/building/${buildingId}/photospheres/${photosphereId}/1`,
          }}
        />
      )
    }

    const crumbs = computeCrumbsFromProps(this.props, {
      ":photosphere_id": current_photosphere.name,
      ":building-name": buildingName,
      ":view-name": "Vue " + viewId,
    })
    const currentDropDownList = Object.keys(current_photosphere.views).map(
      (key) => {
        const obj = current_photosphere.views[key]
        return {
          name: `Vue ${obj.view_id}`,
          id: obj.view_id,
          url: `/building/${buildingId}/photospheres/${photosphereId}/${obj.view_id}`,
        }
      }
    )
    const currentViewName = `Vue ${current_view.view_id}`
    const { level } = current_photosphere

    return (
      <div
        style={{
          marginBottom: "15px",
          flex: "1",
          display: "flex",
          flexDirection: "column",
          cursor: this.state.initDownload ? "wait" : "auto",
        }}
      >
        <Container fluid style={{ margin: "14px 0px" }}>
          <Breadcrumb
            crumbs={crumbs}
            currentDropDownList={currentDropDownList}
            currentName={currentViewName}
          />
        </Container>
        <div className="photosphere-container">
          {current_photosphere.has_map ? (
            <div className="photosphere-pane small">
              <LeafletMap
                photosphereId={photosphereId}
                buildingId={buildingId}
                targetFloorName={level}
                floors={levels}
                editEnabled={this.state.admin}
                className={"small"}
                handleMarkerClick={this.goToPhotosphere.bind(this)}
              />
            </div>
          ) : null}
          {this.state.goToPhotosphere != null ? (
            <Redirect push to={this.state.goToPhotosphere} />
          ) : null}
          {this.state.editMode ? (
            <PhotosphereMarkerEdit
              state={this.state}
              params={this.props.match.params}
              clearSelection={this.clearSelection}
              handleRemoveMarker={this.handleRemoveMarker}
              handleEquipementIdChange={this.handleUpdateMarker_equipementId}
              handleViewIdChange={this.handleUpdateMarker_viewId}
              handleTypeChange={this.handleUpdateMarker_type}
            />
          ) : this.state.current_equipment_id ? (
            <div className="model-viewer-info-container">
              <ModelInfo
                buildingId={buildingId}
                equipmentId={this.state.current_equipment_id}
                close={this.clearSelection}
              />
            </div>
          ) : null}
          {this.state.admin && (
            <div className="admin-menu">
              {this.state.current_items === undefined ? (
                <Button variant="secondary" onClick={this.swithToEditMode}>
                  Édition
                </Button>
              ) : null}
              {this.state.current_items !== undefined ? (
                <Button variant="primary" onClick={this.save}>
                  Sauvegarder
                </Button>
              ) : null}
              {this.state.current_items !== undefined ? (
                <Button variant="secondary" onClick={this.cancel}>
                  Annuler
                </Button>
              ) : null}
              {this.state.error_message}
            </div>
          )}
          <PhotoSphereViewer
            height="100%"
            width="100%"
            src={current_view.url}
            items={this.state.current_items || current_view.items}
            currentItem={this.state.current_item_index}
            onMarkerSelect={this.handleMarkerSelect}
            onDoubleClick={this.handleDoubleClick}
          />
        </div>
      </div>
    )
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  updateVisiblePhotosphere = (
    buildingId,
    photosphereId,
    selectedPhotosphereState
  ) => {
    console.log(
      "updateVisiblePhotosphere",
      photosphereId,
      selectedPhotosphereState
    )
    if (selectedPhotosphereState === true) {
      this.setState({
        visiblePhotosphereId: photosphereId,
      })
    } else {
      this.setState({
        visiblePhotosphereId: null,
      })
    }
  }

  resetVisiblePhotosphere = () => {
    console.log("Reset Visible Photosphere")
    this.setState({
      visiblePhotosphereId: null,
    })
  }

  goToPhotosphere = (buildingId, photosphereId) => {
    console.log("goToPhotosphere", buildingId, photosphereId)
    if (!buildingId || !photosphereId) {
      return
    }
    this.setState({
      goToPhotosphere: `/building/${buildingId}/photospheres/${photosphereId}`,
    })
  }

  swithToEditMode = () => {
    const photosphereId = this.props.match.params.photosphere_id
    const viewId = this.props.match.params.view_id
    const current_photosphere = this.state.photospheres[photosphereId]
    const current_view = current_photosphere.views[viewId]
    const items = this.state.current_items || current_view.items
    this.setState({
      editMode: true,
      current_items: items,
    })
  }

  save = () => {
    const photosphereId = this.props.match.params.photosphere_id
    const viewId = this.props.match.params.view_id
    const newPhotosphere = JSON.parse(JSON.stringify(this.state.photospheres))
    newPhotosphere[photosphereId].views[viewId].items = this.state.current_items
    Amplify.API.put(
      "main",
      `building/${this.props.match.params.id}/photospheres`,
      { body: newPhotosphere }
    )
      .then((response) => {
        this.setState({
          photospheres: newPhotosphere,
          current_items: undefined,
          current_item_index: undefined,
          error_message: undefined,
          editMode: false,
        })
      })
      .catch((error) => {
        this.setState({
          error_message: "Marker can't be saved: " + error,
        })
      })
  }

  cancel = () => {
    this.setState({
      current_items: undefined,
      current_item_index: undefined,
      error_message: undefined,
      editMode: false,
    })
  }

  clearSelection = () => {
    this.setState({
      current_item_index: undefined,
      current_equipment_id: undefined,
    })
  }

  handleMarkerSelect = (marker_index, marker) => {
    const editMode = this.state.admin && this.state.current_items
    if (marker.type === "view" && !editMode) {
      const buildingId = this.props.match.params.id
      const photosphereId = this.props.match.params.photosphere_id
      this.props.history.push(
        `/building/${buildingId}/photospheres/${photosphereId}/${marker.view_id}`
      )
    } else {
      this.setState({
        current_item_index: marker_index,
        current_equipment_id: marker.equipment_id,
      })
    }
  }

  handleDoubleClick = (latitude, longitude) => {
    if (this.state.current_items !== undefined) {
      const new_item = {
        equipment_id: "",
        type: "equipment",
        latitude: latitude,
        longitude: longitude,
      }
      this.setState({
        current_items: [...this.state.current_items, new_item],
        current_item_index: this.state.current_items.length,
      })
    }
  }

  handleUpdateMarker_equipementId = (equipment_id) => {
    const items = this.state.current_items.map((mark, i) => {
      if (i === this.state.current_item_index) {
        mark["equipment_id"] = equipment_id
      }
      return mark
    })
    this.setState({
      current_items: items,
    })
  }

  handleUpdateMarker_viewId = (view_id) => {
    const items = this.state.current_items.map((mark, i) => {
      if (i === this.state.current_item_index) {
        mark["view_id"] = view_id
      }
      return mark
    })
    this.setState({
      current_items: items,
    })
  }

  handleUpdateMarker_type = (type) => {
    const items = this.state.current_items.map((mark, i) => {
      if (i === this.state.current_item_index) {
        mark["type"] = type
      }
      return mark
    })
    this.setState({
      current_items: items,
    })
  }

  handleRemoveMarker = () => {
    const items = [...this.state.current_items]
    items.splice(this.state.current_item_index, 1)
    this.setState({
      current_items: items,
      current_item_index: undefined,
      current_equipment_id: undefined,
    })
  }
}

const PhotosphereMarkerEdit = ({
  state,
  params,
  clearSelection,
  handleRemoveMarker,
  handleEquipementIdChange,
  handleViewIdChange,
  handleTypeChange,
}) => {
  if (state.current_item_index === undefined) return null

  const current_photosphere = state.photospheres[params.photosphere_id]
  const current_view = current_photosphere.views[params.view_id]
  const items = state.current_items || current_view.items

  const type = items[state.current_item_index].type
  const current_equipment_id = state.current_equipment_id
  const current_marker_view_id = items[state.current_item_index].view_id
  let label

  switch (type) {
    case "equipment":
      label = current_equipment_id ? (
        <span>{current_equipment_id}</span>
      ) : (
        <i>Sans titre</i>
      )
      break
    case "view":
      label = current_marker_view_id ? (
        <span>Lien vers la vue {current_marker_view_id}</span>
      ) : (
        <i>Sans titre</i>
      )
      break
    default:
      label = <i>Sans titre</i>
  }

  return (
    <Card
      className="photosphere-marker-detail card-small"
      style={{ overflow: "hidden" }}
    >
      <Card.Header className="border-bottom d-flex justify-content-between">
        <div className="d-flex flex-column">
          <h6 className="m-0">{label}</h6>
          <span
            className="photosphere-marker-detail__delete"
            onClick={handleRemoveMarker}
          >
            Supprimer ce marqueur
          </span>
        </div>
        <i
          onClick={clearSelection}
          className="photosphere-marker-detail__close material-icons mr-1"
        >
          close
        </i>
      </Card.Header>

      <Card.Body>
        <ListGroup variant="flush">
          <ListGroup.Item className="p-0">
            <Form.Group>
              <Form.Label>Type de marqueur</Form.Label>
              <Form.Control
                as="select"
                custom
                value={type}
                onChange={(event) => handleTypeChange(event.target.value)}
              >
                <option value="equipment">Équipement</option>
                <option value="view">Lien vers une vue</option>
              </Form.Control>
            </Form.Group>
            {type === "equipment" && (
              <Form.Group controlId="equipmentId">
                <Form.Label>Identifiant</Form.Label>
                <Form.Control
                  value={current_equipment_id}
                  onChange={(event) =>
                    handleEquipementIdChange(event.target.value)
                  }
                />
              </Form.Group>
            )}
            {type === "view" && (
              <Form.Group controlId="equipmentId">
                <Form.Label htmlFor="equipmentId">Identifiant vue</Form.Label>
                <Form.Control
                  id="viewId"
                  value={items[state.current_item_index].view_id}
                  onChange={(event) => handleViewIdChange(event.target.value)}
                />
              </Form.Group>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  )
}

function Property(props) {
  return (
    <dl>
      <dt>{props.type}</dt>
      <dd>{props.value || props.children}</dd>
    </dl>
  )
}

const EditForm = ({ state, params, photosphereId }) => {
  const current_photosphere = state.photospheres[photosphereId]
  const [show, setShow] = useState(false)
  const [sending, setSending] = useState(false)
  const [name, setName] = useState(current_photosphere.name)
  const [level, setLevel] = useState(current_photosphere.level)
  const [error, setError] = useState("")

  const post = () => {
    setSending(true)
    const newPhotosphere = JSON.parse(JSON.stringify(state.photospheres))
    newPhotosphere[photosphereId].name = name
    newPhotosphere[photosphereId].level = level
    Amplify.API.put("main", `building/${params.id}/photospheres`, {
      body: newPhotosphere,
    })
      .then((response) => {
        window.location.reload()
      })
      .catch((error) => {
        setError(error)
        setSending(false)
      })
  }

  const cancel = () => {
    setName(current_photosphere.name)
    setLevel(current_photosphere.level)
    setShow(false)
  }

  return (
    <div className="d-flex flex-wrap">
      <Modal show={show} centered onHide={cancel}>
        <Modal.Header>
          <h5 className="modal-title">
            Configuration de la vue {current_photosphere.name}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Property type="Nom">
                <Form.Control
                  id="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </Property>
              <Property type="Niveau">
                <Form.Control
                  id="level"
                  value={level}
                  onChange={(event) => setLevel(event.target.value)}
                />
              </Property>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {error}
          <Button variant="danger" onClick={cancel}>
            Annuler
          </Button>
          <SendingButton variant="primary" sending={sending} onClick={post}>
            Valider
          </SendingButton>
        </Modal.Footer>
      </Modal>
      <Button
        variant="secondary"
        onClick={() => setShow(true)}
        style={{ zIndex: 1 }}
      >
        Édition
      </Button>
    </div>
  )
}

const eventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail))
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { event: data }))
  },
  remove(event, callback) {
    document.removeEventListener(event, callback)
  },
}

export default withRouter(Photospheres)
