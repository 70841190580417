import { Button } from "react-bootstrap"
import React, { Component } from "react"

export class LeafletEdition extends Component {
  render() {
    return (
      <>
        {!this.props.markerSelected && this.props.editEnabled ? (
          this.props.editMode ? (
            <>
              <Button
                variant={"secondary"}
                className={"map-edit-cancel-button"}
                onClick={this.props.handleClickEditCancel.bind(this)}
              >
                Annuler
              </Button>
              <Button
                variant={"primary"}
                className={"map-edit-save-button"}
                onClick={this.props.handleClickEditSave.bind(this)}
              >
                Sauvegarder
              </Button>
            </>
          ) : (
            <Button
              variant={"secondary"}
              className={"map-edit-button"}
              onClick={this.props.handleClickEnableEdit.bind(this)}
            >
              Édition
            </Button>
          )
        ) : null}
      </>
    )
  }
}
