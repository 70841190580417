import { API as AmplifyAPI, Auth } from "aws-amplify"

const MAIN_API_ID = "main"
const GED_API_ID = "main"

AmplifyAPI.configure({
  endpoints: [
    {
      name: MAIN_API_ID,
      endpoint: process.env.REACT_APP_URL_ENDPOINT,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }
      },
    },
  ],
})

export interface Building {
  id: string
  name?: string
  forge_model_urn?: string
  areaUnit?: string
  ged?: string
  area?: number
  client?: string
  facilityManager?: string
  imgUrlThumbnail?: string
  lon?: number
  lat?: number
}

export interface Equipment {
  equipment_id: string
  label?: string
  family?: string
  familyLabel?: string
  space?: string
  zone?: string
  workTeam?: string
  interventions: EquipmentIntervention[]
  documents?: File[]
}

export interface EquipmentIntervention {
  id: number
  label?: string
  realizedBeginDate?: string
  firstPlanificationBeginDate: string
  workTeam?: string
  status?: string
  activityLabel?: string
  domainLabel?: string
  code?: string
}

export const getMainAPI = () => new MainAPI()
export const getGedAPI = () => new GedAPI()

class MainAPI {
  getBuilding(buildingId: string): Promise<Building> {
    return AmplifyAPI.get("main", `/building/${buildingId}`, {}).then(
      (body) => {
        if (!body.id) {
          throw Error("Bad format for building response body")
        }
        return body as Building
      }
    )
  }

  getEquipment(buildingId: string, equipmentId: string): Promise<Equipment> {
    return AmplifyAPI.get(
      MAIN_API_ID,
      `building/${buildingId}/equipment/${equipmentId}`,
      {}
    ).then((body) => {
      if (!body?.equipment_id) {
        throw Error("Bad format for equipment response body")
      }
      return body as Equipment
    })
  }
}

export type Resource = File | Folder

export interface File {
  kind: "File"
  id: string
  name: string
  path: string
  size: number
  last_modified: string
}

export interface Folder {
  kind: "Folder"
  name: string
  path: string
  content?: Array<Resource>
  last_modified: string
}

class GedAPI {
  getFolder(path?: string[]): Promise<Folder> {
    const url = path?.length
      ? `documents/folder/${path.join("%2F")}`
      : `documents`

    return AmplifyAPI.get(GED_API_ID, url, {}).then((folder) => {
      if (folder?.kind !== "Folder") {
        throw Error("Bad format for ged get folder response body")
      }
      return folder as Folder
    })
  }
}
