import React from "react"
import { Badge } from "react-bootstrap"

export default class ServiceBadge extends React.Component {
  render() {
    if (!this.props.value) return null

    const value = this.props.value && this.props.value.toUpperCase()

    const service = SERVICES[value] || {
      variant: "info",
      description: "",
    }

    return (
      <Badge variant={service.variant} title={service.description}>
        {service.category || value}
      </Badge>
    )
  }
}

const SERVICES = {
  GMAO: {
    variant: "dark",
    description: "Les fonctionalités GMAO sont disponibles pour ce bâtiment.",
  },
  BIM: {
    variant: "danger",
    description: "La modélisation BIM est disponible pour ce bâtiment.",
  },
  GED: {
    variant: "info",
    description:
      "La gestion électronique des documents est disponible pour ce bâtiment.",
  },
  IOT: {
    variant: "info",
    description: "Des données IOT sont disponibles pour ce bâtiment.",
  },
  "360°": {
    variant: "secondary",
    description: "Des photospheres sont disponibles pour ce bâtiment.",
  },
  BI: {
    variant: "warning",
    description: "Un rapport BI est disponible pour ce bâtiment.",
  },
}
