import { Component } from "react"
import { MapContainer, ImageOverlay, MapConsumer, Marker } from "react-leaflet"

import "leaflet/dist/leaflet.css"
import L from "leaflet"
import dateUtils from "../../utils/date"
import imageUtils from "../../utils/image"
import { LeafletMarkerForm } from "./LeafletMarkerForm"
import { LeafletUI } from "./LeafletUI"
import Amplify from "aws-amplify"

const noMapFoundUrl = "/../../../../images/no_map_found.png"
const noMapFoundName = "Aucun"
const noMapFoundMockFloors = [
  {
    name: noMapFoundName,
    url: noMapFoundUrl,
    markers: [],
  },
]

const markerUnselectedUrl = "/../../../../images/black-eye.png"
const markerSelectedUrl = "/../../../../images/green-eye.png"
const customPinSelected = L.divIcon({
  className: "location-pin",
  html: `<img src="${markerSelectedUrl}" alt="marker icon selected">`,
  iconSize: [40, 40],
  iconAnchor: [13, 11],
})
const customPin = L.divIcon({
  className: "location-pin",
  html: `<img src="${markerUnselectedUrl}" alt="marker icon">`,
  iconSize: [40, 40],
  iconAnchor: [13, 11],
})

export class LeafletMap extends Component {
  constructor(props) {
    super(props)

    const initialBounds = L.latLngBounds([
      [0, 0],
      [0, 0],
    ])
    this.map = null

    this.editEnabled =
      this.props.editEnabled == null ? false : this.props.editEnabled

    this._mapIsInit = false

    let floors
    let lock = false
    if (this.props.floors != null && this.props.floors.length > 0) {
      // Copy floors, because props are un-mutable.
      floors = Object.assign({}, this.props.floors)
      // Currently, there is an object simulating a list {'0': n, '1', m, ...}, turn it into a list [n, m, ...].
      floors = Object.values(floors)
    } else {
      console.warn("Leaflet Map: no floors set", this.props.floors)
      floors = noMapFoundMockFloors
      lock = true
    }

    this.state = {
      currentZoomLevel: 1,
      bounds: initialBounds,
      indexFloor: null,
      floors: floors,
      displayMarkerForm: false,
      selectedPhotosphereId: this.props.photosphereId
        ? this.props.photosphereId
        : null,
      editMode: false,
      redirect: false,
      lock: lock,
      editMarkerInput: "",
    }
    this.backUpFloors = []
  }

  async componentDidMount() {
    console.log("Mounted...")

    // Either a floor is specified or there is a default floor.
    try {
      if (this.props.targetFloorName) {
        await this.updateTargetFloor(this.props.targetFloorName)
      } else if (this.props.defaultLevel) {
        await this.updateTargetFloor(this.props.defaultLevel)
      } else {
        console.warn(
          "Leaflet Map: level not set",
          this.props.targetFloorName,
          this.props.defaultLevel
        )
        this.setState(
          {
            floors: noMapFoundMockFloors,
            lock: true,
          },
          async () => {
            await this.updateTargetFloor(noMapFoundName)
          }
        )
      }
    } catch (e) {
      console.error(
        "An error occurred when mounting component",
        e,
        this.props.targetFloorName,
        this.props.defaultLevel
      )
      this.setState(
        {
          floors: noMapFoundMockFloors,
          lock: true,
        },
        async () => {
          await this.updateTargetFloor(noMapFoundName)
        }
      )
    }
  }

  componentWillUnmount() {
    console.log("Will unmount")
  }

  render() {
    if (!this.checkFloorsAndIndex()) {
      console.log(
        "render: floors or indexFloor states are not set or invalid",
        this.state.floors,
        this.state.indexFloor
      )
      return null
    }

    const { url } = this.state.floors[this.state.indexFloor]

    return (
      <div
        className={`map-container ${this.props.className} ${
          this.state.displayMarkerForm ? "marker-form-enabled" : ""
        }`}
      >
        {!this.state.lock ? (
          <LeafletUI
            editMode={this.state.editMode}
            markerSelected={this.state.displayMarkerForm}
            handleClickEditCancel={this.handleClickEditCancel.bind(this)}
            handleClickEditSave={this.handleClickEditSave.bind(this)}
            handleClickEnableEdit={this.handleClickEnableEdit.bind(this)}
            handleFloorDropdownChange={this.handleFloorDropdownChange.bind(
              this
            )}
            editEnabled={this.props.editEnabled}
            floors={this.state.floors}
          />
        ) : null}
        <MapContainer
          center={[0, 0]}
          zoom={1}
          zoomSnap={0.2}
          zoomDelta={0.2}
          wheelPxPerZoomLevel={250}
        >
          <ImageOverlay
            url={url}
            bounds={this.state.bounds}
            attribution="&copy; setec bâtiment"
          >
            {this.state.floors[this.state.indexFloor].markers.map((m) => (
              <Marker
                key={m.id}
                id={m.id}
                // Can only drag in edit mode
                draggable={this.state.editMode}
                position={[m.lat, m.lng]}
                icon={m.selected ? customPinSelected : customPin}
                eventHandlers={{
                  click: this.handleClickMarker.bind(this),
                  dragend: this.updateMarkerPosition.bind(this),
                }}
              />
            ))}
          </ImageOverlay>
          <MapConsumer>
            {(map) => {
              this.initMap(map)

              return null
            }}
          </MapConsumer>
        </MapContainer>
        {this.state.displayMarkerForm ? (
          <LeafletMarkerForm
            handleClickMarkerEditCancel={this.handleClickMarkerEditCancel.bind(
              this
            )}
            handleClickMarkerEditSave={this.handleClickMarkerEditSave.bind(
              this
            )}
            handleChangeMarkerEditSave={this.handleChangeMarkerEditSave.bind(
              this
            )}
            handleClickMarkerEditDelete={this.handleClickMarkerEditDelete.bind(
              this
            )}
            photosphereId={this.state.editMarkerInput}
          />
        ) : null}
      </div>
    )
  }

  async updateTargetFloor(targetFloorName, performMapUpdate = false) {
    if (this.state.floors == null) {
      console.log(
        "updateTargetFloor: floors is not valid",
        targetFloorName,
        this.state.floors
      )
      return -1
    }

    const indexFloor = this.getFloorIndex(targetFloorName)

    if (indexFloor < 0 || indexFloor >= this.state.floors.length) {
      console.log(
        "updateTargetFloor: floor name is not valid",
        targetFloorName,
        indexFloor,
        this.state.floors
      )
      return -1
    }

    const { url } = this.state.floors[indexFloor]
    const imgMetadata = await imageUtils.getImageMeta(url)
    const { width, height } = imgMetadata

    this.setState(
      {
        indexFloor: indexFloor,
      },
      () => {
        this.updateMapBound(indexFloor, width, height, performMapUpdate)
      }
    )

    return indexFloor
  }

  getFloorIndex(targetFloorName) {
    return this.state.floors.findIndex((e) => e["name"] === targetFloorName)
  }

  initMap(map) {
    if (this._mapIsInit) {
      return
    }

    this.map = map
    this.map.on("zoomend", () => {
      const newZoomLevel = this.map.getZoom()
      this.handleZoomLevelChange(newZoomLevel)
    })

    this.map.on("click", (e) => {
      if (this.state.editMode) {
        this.handleAddMarker(e)
      }
    })

    this.updateMap()

    this._mapIsInit = true
  }

  updateMap() {
    if (this.state.bounds == null) {
      console.log("updateMap: bounds invalid", this.state.bounds)
      return
    }
    console.log("updateMap: bounds valid", this.state.bounds)

    this.map.fitBounds(this.state.bounds)
    this.map.setMaxBounds(this.state.bounds)
  }

  async updateMapBound(indexFloor, width, height, performMapUpdate = false) {
    if (this.state.floors == null || indexFloor >= this.state.floors.length) {
      console.log(
        "updateMapBound: floors is not set or invalid",
        this.state.floors,
        indexFloor
      )
      return
    }
    console.log("updateMapBound: is set", this.state.floors)

    console.log(height, width)

    const bounds = L.latLngBounds([
      [0, 0],
      [height / 10, width / 10],
    ])
    console.log("bounds", bounds)
    this.setState(
      {
        bounds: bounds,
      },
      () => {
        this.resetMarkers()
        if (performMapUpdate) {
          this.updateMap()
        }
      }
    )
  }

  handleZoomLevelChange(newZoomLevel) {
    if (!this._mapIsInit) {
      console.log("handleZoomLevelChange, map is not init", newZoomLevel)
      return
    }
    console.log("handleZoomLevelChange ok", newZoomLevel)
    this.setState({ currentZoomLevel: newZoomLevel })
  }

  handleAddMarker(e) {
    this.unselectAllMarkers()

    const cid = dateUtils.dateTick()
    const marker = {
      id: cid,
      lat: e.latlng.lat,
      lng: e.latlng.lng,
      selected: true,
      photosphereId: "",
    }

    // Complex operation to add marker to specific floor markers list.
    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = [
      marker,
      ...newFloors[this.state.indexFloor].markers,
    ]
    // Currently, there is an object simulating a list {'0': n, '1', m, ...}, let us turn it into a list [n, m, ...]
    newFloors = Object.values(newFloors)

    // Automatically select added marker.
    this.setState({
      floors: newFloors,
      displayMarkerForm: true,
      selectedPhotosphereId: null,
      editMarkerInput: "",
    })
  }

  updateMarkerPosition(e) {
    if (!this.checkFloorsAndIndex()) {
      console.log(
        "updateMarkerPosition: floors or indexFloor states are not set or invalid",
        this.state.floors,
        this.state.indexFloor
      )
      return null
    }
    const { lat, lng } = e.target.getLatLng()

    // Complex operation to update a marker position
    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = this.state.floors[
      this.state.indexFloor
    ].markers.map((m) => {
      if (m.id === e.target.options.id) {
        m.lat = lat
        m.lng = lng
      }
      return m
    })
    // Currently, there is an object simulating a list {'0': n, '1', m, ...}, let us turn it into a list [n, m, ...]
    newFloors = Object.values(newFloors)

    console.log("updateMarkerPosition", newFloors)

    // update Marker to state
    this.setState({ floors: newFloors })
  }

  checkFloorsAndIndex() {
    return (
      this.state.floors != null &&
      this.state.indexFloor != null &&
      this.state.indexFloor < this.state.floors.length
    )
  }

  resetMarkers() {
    if (this.props.photosphereId == null) {
      this.unselectAllMarkers()
      return
    }

    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = this.state.floors[
      this.state.indexFloor
    ].markers.map((m) => {
      m.selected = m.photosphereId === this.props.photosphereId
      return m
    })
    newFloors = Object.values(newFloors)
    this.setState({
      floors: newFloors,
    })
  }

  handleClickMarker(e) {
    if (this.state.editMode) {
      this.editMarker(e)
    } else {
      let selectedPhotosphereId = null
      let selectedPhotosphereState = null
      let newFloors = Object.assign({}, this.state.floors)
      newFloors[this.state.indexFloor].markers = this.state.floors[
        this.state.indexFloor
      ].markers.map((m) => {
        if (m.id === e.target.options.id) {
          m.selected = !m.selected
          // If selected, set selected to photosphereId otherwise set it to null,
          //  because nothing is selected.
          selectedPhotosphereId = m.photosphereId
          selectedPhotosphereState = m.selected
        } else {
          m.selected = false
        }
        return m
      })

      newFloors = Object.values(newFloors)

      this.setState({
        floors: newFloors,
        selectedPhotosphereId: selectedPhotosphereId,
        editMarkerInput: selectedPhotosphereId,
      })

      if (this.props.buildingId) {
        this.props.handleMarkerClick(
          this.props.buildingId,
          selectedPhotosphereId,
          selectedPhotosphereState
        )
      } else {
        console.log(
          "Could not call parent handleMarkerClick, buildingId is not " +
            "correctly set",
          this.props.buildingId,
          selectedPhotosphereId
        )
      }
    }
  }

  editMarker(e) {
    if (!this.checkFloorsAndIndex()) {
      console.log(
        "editMarker: floors or index states invalid",
        this.state.floors,
        this.state.indexFloor
      )
      return
    }

    let selectedPhotosphereId = ""

    // Complex operation to mark a single marker as selected
    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = this.state.floors[
      this.state.indexFloor
    ].markers.map((m) => {
      if (m.id === e.target.options.id) {
        m.selected = true
        selectedPhotosphereId = m.photosphereId
      } else {
        m.selected = false
      }
      return m
    })
    // Currently, there is an object simulating a list {'0': n, '1', m, ...}, let us turn it into a list [n, m, ...]
    newFloors = Object.values(newFloors)

    this.setState({
      floors: newFloors,
      displayMarkerForm: true,
      selectedPhotosphereId: selectedPhotosphereId,
      editMarkerInput: selectedPhotosphereId,
    })
  }

  getPhotosphereIdFromMarker(e) {
    const { markers } = this.state.floors[this.state.indexFloor]
    console.log("getPhotosphereIdFromMarker", e, markers)
    for (const i in markers) {
      const m = markers[i]
      console.log(m)
      if (m.id === e.target.options.id) {
        const { photosphereId } = m
        return photosphereId
      }
    }

    return null
  }

  handleChangeMarkerEditSave(e) {
    this.setState({
      editMarkerInput: e.target.value,
    })
  }

  handleClickMarkerEditCancel() {
    if (!this.checkFloorsAndIndex()) {
      console.log(
        "handleClickMarkerEditCancel: floors or indexFloor states are not set or invalid",
        this.state.floors,
        this.state.indexFloor
      )
      return null
    }

    // Complex operation to unselect all markers
    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = this.state.floors[
      this.state.indexFloor
    ].markers.map((m) => {
      m.selected = false
      return m
    })
    // Currently, there is an object simulating a list {'0': n, '1', m, ...}, let us turn it into a list [n, m, ...]
    newFloors = Object.values(newFloors)

    this.setState({
      floors: newFloors,
      displayMarkerForm: false,
    })
  }

  handleClickMarkerEditSave() {
    if (!this.checkFloorsAndIndex()) {
      console.log(
        "handleClickMarkerEditSave: floors or indexFloor states are not set or invalid",
        this.state.floors,
        this.state.indexFloor
      )
      return null
    }

    console.log("handleClickMarkerEditSave", this.state.editMarkerInput)

    // Complex operation to edit selected markers
    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = this.state.floors[
      this.state.indexFloor
    ].markers.map((m) => {
      if (m.selected) {
        m.photosphereId = this.state.editMarkerInput
        m.selected = false
      }
      return m
    })
    // Currently, there is an object simulating a list {'0': n, '1', m, ...}, let us turn it into a list [n, m, ...]
    newFloors = Object.values(newFloors)

    this.setState({
      floors: newFloors,
      displayMarkerForm: false,
      editMarkerInput: "",
      selectedPhotosphereId: null,
    })
  }

  handleClickMarkerEditDelete() {
    // Complex operation to remove selected markers
    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = this.state.floors[
      this.state.indexFloor
    ].markers.filter(function (m) {
      return !m.selected
    })
    // Currently, there is an object simulating a list {'0': n, '1', m, ...}, let us turn it into a list [n, m, ...]
    newFloors = Object.values(newFloors)

    this.setState({
      floors: newFloors,
      displayMarkerForm: false,
    })
  }

  handleClickEnableEdit() {
    // Copy floors dictionary, this ensures the user can roll their changes back by clicking on the cancel button.
    this.backUpFloors = JSON.parse(JSON.stringify(this.state.floors))

    // Call parent handler when edit button is clicked
    if (this.props.parentEditHandler != null) {
      this.props.parentEditHandler()
    }

    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = this.state.floors[
      this.state.indexFloor
    ].markers.map((m) => {
      m.selected = false
      return m
    })
    newFloors = Object.values(newFloors)

    this.setState((prevState) => {
      return {
        floors: newFloors,
        editMode: !prevState.editMode,
        selectedPhotosphereId: null,
        editMarkerInput: "",
      }
    })
  }

  handleClickEditSave() {
    // Copy floors dictionary, this ensures the user can roll their changes back by clicking on the cancel button.
    this.backUpFloors = JSON.parse(JSON.stringify(this.state.floors))

    // Remove all markers without a photosphere ID
    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = newFloors[
      this.state.indexFloor
    ].markers.filter((m) => {
      return !!m.photosphereId
    })
    newFloors = Object.values(newFloors)
    this.setState(
      {
        floors: newFloors,
      },
      () => {
        const route = `building/${this.props.buildingId}/levels`
        Amplify.API.put("main", route, {
          body: this.state.floors,
        })
          .then(() => {
            this.resetMarkers()
            this.setState({
              editMode: false,
            })
          })
          .catch((error) => {
            console.warn(error)
          })
      }
    )
  }

  handleClickEditCancel() {
    this.resetMarkers()
    this.setState({
      editMode: false,
      floors: this.backUpFloors,
    })
  }

  handleFloorDropdownChange(name) {
    this.resetMarkers()

    return this.updateTargetFloor(name, true)
  }

  unselectAllMarkers() {
    // Complex operation to unselect all markers
    let newFloors = Object.assign({}, this.state.floors)
    newFloors[this.state.indexFloor].markers = this.state.floors[
      this.state.indexFloor
    ].markers.map((m) => {
      m.selected = false
      return m
    })
    // Currently, there is an object simulating a list {'0': n, '1', m, ...}, let us turn it into a list [n, m, ...]
    newFloors = Object.values(newFloors)

    // Call parent handle marker click with a null parameter to simulate a deselection from the user
    this.props.handleMarkerClick(this.props.buildingId, null)

    this.setState({
      floors: newFloors,
      displayMarkerForm: false,
    })
  }
}
