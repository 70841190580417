import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import routes from "./routes"
import { DefaultLayout } from "./layouts"
import Errors from "./views/Errors"
import { UserContextProvider } from "./contexts/UserContext"

export default class App extends React.Component {
  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <UserContextProvider>
          <div>
            <DefaultLayout signOut={this.props.signOut} user={this.props.user}>
              <Switch>
                {routes.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      render={(props) => {
                        return <route.component {...props} />
                      }}
                    />
                  )
                })}
                <Route path="*">
                  <Errors />
                </Route>
              </Switch>
            </DefaultLayout>
          </div>
        </UserContextProvider>
      </Router>
    )
  }
}
