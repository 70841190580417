import React, { Component } from "react"
import { Dropdown } from "react-bootstrap"
import { Redirect } from "react-router-dom"

export class LinkDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clickedUrl: "",
    }
  }

  render() {
    return (
      <>
        <Dropdown size="sm" className="mr-2 d-inline">
          <Dropdown.Toggle className={"breadcrumb-dropdown"}></Dropdown.Toggle>
          <Dropdown.Menu>
            {this.props.links != null
              ? this.props.links.map((x) => (
                  <Dropdown.Item
                    key={`linkDropdown_${x.name}`}
                    onClick={() => {
                      this.setState({
                        clickedUrl: x.url,
                      })
                    }}
                  >
                    {x.name}
                  </Dropdown.Item>
                ))
              : null}
          </Dropdown.Menu>
        </Dropdown>
        {this.state.clickedUrl ? (
          <Redirect push to={this.state.clickedUrl} />
        ) : null}
      </>
    )
  }
}
