import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import MainNavbar from "../components/layout/MainNavbar/MainNavbar"
import MainSidebar from "../components/layout/MainSidebar/MainSidebar"

const DefaultLayout = ({ children, signOut, user }) => (
  <Container fluid>
    <Row>
      <MainSidebar />
      <Col
        style={{ minHeight: "100vh" }}
        className="main-content p-0 d-flex flex-column"
        lg={{ span: 10, offset: 2 }}
        md={{ span: 9, offset: 3 }}
        sm={12}
        tag="main"
      >
        <MainNavbar signOut={signOut} user={user} />
        {children}
      </Col>
    </Row>
  </Container>
)

export default DefaultLayout
