import React from "react"
import { Redirect } from "react-router-dom"
import Building from "./views/Building"
import Buildings from "./views/Buildings"
import Dashboard from "./views/Dashboard"
import Document from "./views/Document"
import Photospheres from "./views/Photospheres"
import Viewer3D from "./views/Viewer3D"
import ChangeLog from "./views/ChangeLog"

// eslint-disable-next-line
export default [
  {
    path: "/dashboard",
    exact: true,
    component: Dashboard,
    name: "Accueil",
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/building" />,
  },
  {
    path: "/building",
    exact: true,
    component: Buildings,
    name: "Vos actifs",
  },
  {
    path: "/building/:id",
    exact: true,
    component: Building,
    name: ":building-name",
  },
  {
    path: "/building/:id/dashboard",
    exact: true,
    component: Dashboard,
    name: "Tableau de bord",
  },
  {
    path: "/building/:id/photospheres",
    exact: true,
    component: Photospheres,
    name: "Locaux techniques",
  },
  {
    path: "/building/:id/photospheres/:photosphere_id",
    exact: true,
    component: Photospheres,
    name: ":photosphere_id",
  },
  {
    path: "/building/:id/photospheres/:photosphere_id/:view_id",
    exact: true,
    component: Photospheres,
    name: ":view-name",
  },
  {
    path: "/building/:id/3d-viewer",
    exact: true,
    component: Viewer3D,
    name: "Maquette 3D BIM",
  },
  {
    path: "/documents",
    exact: false,
    component: Document,
    name: "Vos fichiers",
  },
  {
    path: "/changelog",
    exact: false,
    component: ChangeLog,
    name: "Change log",
  },
]
