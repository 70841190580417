import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import routes from "../../routes"
import { LinkDropdown } from "./LinkDropdown"

interface BreadcrumbsProps {
  crumbs: any[]
  currentDropDownList?: any[]
  currentName?: any
}

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  crumbs,
  currentDropDownList,
  currentName,
}) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 0) {
    return null
  }
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb mb-0">
        {/* Link back to any previous steps of the breadcrumb. */}
        {crumbs.map(({ name, path }, key) => {
          const isCurrent = key + 1 === crumbs.length

          if (isCurrent) {
            if (currentDropDownList != null) {
              return (
                <li
                  key={key}
                  className={"breadcrumb-item active"}
                  aria-current="page"
                >
                  {currentName}
                  <LinkDropdown links={currentDropDownList} />
                </li>
              )
            } else {
              return (
                <li
                  key={key}
                  className={"breadcrumb-item active"}
                  aria-current="page"
                >
                  {name}
                </li>
              )
            }
          }

          return (
            <li key={key} className="breadcrumb-item">
              <Link to={path}>{name}</Link>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export function computeCrumbsFromProps(props: any, resolvers = {} as any) {
  const crumbs = routes
    // Get all routes that contain the current one.
    .reduce((acc, route) => {
      const { path } = route
      if (Array.isArray(path)) {
        for (const p of path) {
          if (props.match.path.includes(p) && p !== "/") {
            const r = { ...route, path: p }
            acc.push(r)
            break
          }
        }
      } else {
        if (props.match.path.includes(path) && path !== "/") acc.push(route)
      }
      return acc
    }, [] as any[])
    // Swap out any dynamic routes with their param values.
    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
    .map(({ path, name, ...rest }) => {
      return {
        path: Object.keys(props.match.params).length
          ? Object.keys(props.match.params).reduce(
              (path, param) =>
                path.replace(`:${param}`, props.match.params[param]),
              path
            )
          : path,
        name: resolvers[name] || name,
        ...rest,
      }
    })
  return crumbs
}

export default Breadcrumbs
