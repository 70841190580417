import React from "react"
import PropTypes from "prop-types"
import { Navbar } from "react-bootstrap"
import { Auth } from "aws-amplify"

import { Dispatcher, Constants } from "../../../flux"

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      group: null,
    }
    this.handleToggleSidebar = this.handleToggleSidebar.bind(this)
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    })
  }

  componentDidMount() {
    Auth.currentSession().then((info) => {
      const groups = info.getAccessToken().payload["cognito:groups"]
      let logo = null
      if (groups.includes("perimetre:covea")) {
        logo = "/images/clients/covea.jpg"
      } else {
        logo = "/images/clients/setec.jpg"
      }
      this.setState({
        logo,
      })
    })
  }

  render() {
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
          type="light"
        >
          <Navbar.Brand
            className="w-100 mr-0"
            href="/building"
            style={{ lineHeight: "25px" }}
          >
            <div className="d-table m-auto">
              {this.state.logo && (
                <img
                  id="main-logo"
                  className="d-inline-block align-top mr-1"
                  style={{ maxHeight: "50px" }}
                  src={this.state.logo}
                  alt="Setec"
                />
              )}
            </div>
          </Navbar.Brand>
          {/* eslint-disable-next-line */}
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={this.handleToggleSidebar}
          >
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    )
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
}

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
}

export default SidebarMainNavbar
