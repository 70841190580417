/* global Autodesk, THREE */

import React, {
  Dispatch,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react"
import { API } from "aws-amplify"
import "./ToolbarExtension"
import "./ModelViewer.scss"
import ModelInfo from "../../components/model-info/ModelInfo"

const VIEWER_DIV_ID = "model-viewer"
const UNIFIED_ID_ATT_NAME = "id_datalake"

interface ModelViewerProps {
  buildingId: string
  urn: string
}

const initialize = (
  urn: string,
  viewer: React.MutableRefObject<Autodesk.Viewing.GuiViewer3D | undefined>,
  setSelectedId: Dispatch<number | undefined>
) => {
  const options: Autodesk.Viewing.InitializerOptions = {
    getAccessToken: (callback: (token: string, expires: number) => any) => {
      API.post("main", `bim/token`, {
        body: {},
      }).then((response) => {
        if (!response) {
          console.error("can't get an access token")
          return
        }

        callback(response.access_token, response.expires_in)
      })
    },
  }

  Autodesk.Viewing.Initializer(options, function () {
    const config3d = {
      extensions: [
        "ToolbarExtension",
        "Autodesk.AEC.LevelsExtension",
        "Autodesk.DocumentBrowser",
      ],
      memory: {
        limit: 1000, // in MB
      },
    }
    const htmlDiv = document.getElementById(VIEWER_DIV_ID)
    viewer.current = new Autodesk.Viewing.GuiViewer3D(htmlDiv!, config3d)
    const startedCode = viewer.current.start()
    if (startedCode > 0) {
      console.error("Failed to create a Viewer: WebGL not supported.")
    }

    loadModel(urn, viewer.current, setSelectedId)
  })
}

const loadModel = (
  urn: string,
  viewer: Autodesk.Viewing.GuiViewer3D,
  setSelectedId: Dispatch<number | undefined>
) => {
  const onDocumentLoadSuccess = (doc: any) => {
    const node = doc.getRoot().getDefaultGeometry()
    doc.downloadAecModelData()
    viewer
      .loadDocumentNode(doc, node)
      .then(() => {
        configureViewer(viewer, setSelectedId)
      })
      .catch((err) => {
        console.error("Could not load viewable: " + err)
      })
  }
  const onDocumentLoadFailure = (err: any) => {
    console.error("Could not load document: " + err)
  }
  Autodesk.Viewing.Document.load(
    "urn:" + urn,
    onDocumentLoadSuccess,
    onDocumentLoadFailure
  )
}

const configureViewer = (
  viewer: Autodesk.Viewing.GuiViewer3D,
  setSelectedId: Dispatch<number | undefined>
) => {
  viewer.setFocalLength(200)
  viewer.setQualityLevel(/* ambient shadows */ false, /* antialiasing */ true)
  viewer.setGroundShadow(false)
  viewer.setGhosting(true)
  viewer.setEnvMapBackground(false)
  viewer.setProgressiveRendering(false)
  viewer.setSelectionColor(
    new THREE.Color(0xff0000), // red color
    Autodesk.Viewing.SelectionMode.MIXED
  )
  viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, () => {
    const ids = viewer.getSelection()
    if (!ids!.length) {
      setSelectedId(undefined)
      return
    }
    setSelectedId(ids[0])
  })
}

const removeViewerChildren = () => {
  const viewerElement = document.getElementById(VIEWER_DIV_ID)
  if (viewerElement) {
    viewerElement.innerHTML = ""
  }
}

const ModelViewer: FunctionComponent<ModelViewerProps> = ({
  urn,
  buildingId,
}) => {
  const viewer = useRef<Autodesk.Viewing.GuiViewer3D>()
  const [selectedId, setSelectedId] = useState<number>()
  const [unifiedId, setUnifiedId] = useState<string>()
  const [displayInfo, setDisplayInfo] = useState(false)
  const closeDisplayInfo = () => {
    viewer.current?.clearSelection()
  }

  useEffect(() => {
    // For some reasons, sometime a child is not deleted, and then there are two models.
    // Kill all children before creating another one to avoid that.
    removeViewerChildren()

    initialize(urn, viewer, setSelectedId)
    const currentv = viewer.current

    return () => {
      currentv?.finish()
      Autodesk.Viewing.shutdown()
    }
  }, [urn, viewer])

  // On Selected Id Changed Effet
  useEffect(() => {
    if (!viewer?.current || selectedId === undefined) {
      setDisplayInfo(false)
      return
    }

    viewer.current.getProperties(selectedId, ({ properties }) => {
      //const datalakeId = "S2C1 DESVSD 17"
      const datalakeId = properties.find(
        (p) => p.attributeName === UNIFIED_ID_ATT_NAME
      )?.displayValue

      if (datalakeId) {
        setDisplayInfo(true)
        setUnifiedId(datalakeId)
      } else {
        setUnifiedId(undefined)
      }
    })
  }, [selectedId, buildingId])

  return (
    <div id={"model-viewer-container"}>
      <div className="model-viewer-info-container">
        {displayInfo ? (
          <ModelInfo
            buildingId={buildingId}
            equipmentId={unifiedId}
            close={closeDisplayInfo}
          />
        ) : null}
      </div>
      <div id={VIEWER_DIV_ID} />
    </div>
  )
}

export default ModelViewer
