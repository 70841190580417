import { FunctionComponent } from "react"
import { File } from "../../utils/api"
import { download } from "../../utils/ged"
import "./GedResource.scss"
import { FileIcon } from "../common/FileIcon"

export interface GedResourceProps {
  resource: File
}

const GedResource: FunctionComponent<GedResourceProps> = ({ resource }) => {
  return (
    <div
      className="ged-resource ged-resource--file"
      onClick={() => download(resource.id, resource.name, true)}
    >
      <span className="ged-ressource__icon">
        <FileIcon file={resource} width="22px" />
      </span>
      <span className="ged-resource__name">{resource.name}</span>
    </div>
  )
}

export default GedResource
