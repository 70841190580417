import React from "react"
import { Container, Button } from "react-bootstrap"
import { Link } from "react-router-dom"

const Errors = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div style={{ height: "100%" }} className="error">
      <div className="error__content">
        <h2>404</h2>
        <h3>Oups...</h3>
        <p>
          L'url que vous souhaitez consulter ne correspond à aucune ressource
          connue.
        </p>
        <Link to="/">
          <Button>&larr; Revenir à l'accueil</Button>
        </Link>
      </div>
    </div>
  </Container>
)

export default Errors
