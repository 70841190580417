import { FC, useEffect, useState } from "react"
import { PowerBIEmbed } from "powerbi-client-react"
import { API } from "aws-amplify"
import Loader from "../common/Loader"

interface ReportViewerProps {
  type: "site" | "perimetre"
  resourceId: string
}

interface ReportEmbedInfo {
  url: string
  token: string
}

export const ReportViewer: FC<ReportViewerProps> = ({ type, resourceId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [reportEmbedInfo, setReportEmbedInfo] = useState<ReportEmbedInfo>()
  useEffect(() => {
    const url =
      type === "site" ? `site/${resourceId}/bi-site/` : `/bi-perimetre`
    API.get("main", url, {}).then((response: ReportEmbedInfo) => {
      setReportEmbedInfo(response)
      setIsLoading(false)
    })
  }, [type, resourceId])

  if (isLoading || !reportEmbedInfo) {
    return (
      <div style={{ height: "100%" }}>
        <Loader fullscreen></Loader>
      </div>
    )
  }

  return (
    <PowerBIEmbed
      embedConfig={{
        type: "report",
        tokenType: 1, // Embed
        accessToken: reportEmbedInfo.token,
        embedUrl: reportEmbedInfo.url,
      }}
      cssClassName="bi-iframe"
    />
  )
}
