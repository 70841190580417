import React from "react"
import { Nav } from "react-bootstrap"

import UserActions from "./UserActions"

// eslint-disable-next-line
export default ({ signOut, user }) => (
  <Nav navbar className="border-left flex-row">
    <UserActions signOut={signOut} user={user} />
  </Nav>
)
