import { Component } from "react"
import moment from "moment"
import numeral from "numeral"
import Loader from "../components/common/Loader"
import { FileIcon } from "../components/common/FileIcon"
import Breadcrumb from "../components/common/Breadcrumb"
import { Container } from "react-bootstrap"
import { withRouter } from "react-router-dom"
import { url } from "gravatar"
import { download } from "../utils/ged"
import { getGedAPI } from "../utils/api"

class Document extends Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      documents: null,
      isLoading: true,
      error: false,
      isInitialized: false,
      currentPath: null,
    }
  }

  componentDidUpdate(prevProps: any) {
    //@ts-ignore
    const location = this.props.location.pathname
    const previousLocation = prevProps.location.pathname
    if (location !== previousLocation) {
      this.getFilesFromLocationPathname()
    }
  }

  componentDidMount() {
    this.getFilesFromLocationPathname()
  }

  render() {
    if (!this.state.isInitialized) {
      return <Loader fullscreen></Loader>
    }

    const documents = this.state.documents
    let content = null
    let total = null

    let crumbs = computeCrumbsFromPath(this.state.currentPath)

    if (this.state.isLoading) {
      content = <Loader fullscreen></Loader>
    } else if (this.state.error) {
      content = (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
          Une erreur est survenue.
        </div>
      )
    } else if (!documents || !documents.length) {
      content = (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
          Ce dossier est vide
        </div>
      )
    } else {
      total = documents.length
      content = (
        <table className="table mb-0">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Nom</th>
              <th className="d-none d-sm-table-cell" scope="col">
                Dernière modification
              </th>
              <th className="d-none d-sm-table-cell" scope="col">
                Modifié par
              </th>
              <th className="d-none d-sm-table-cell" scope="col">
                Taille
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc: any) => {
              const {
                name,
                kind,
                id,
                path,
                last_modified,
                size,
                item_count,
                modified_by,
              } = doc
              switch (kind) {
                case "Folder":
                  return (
                    <tr
                      key={id}
                      className="folder"
                      onClick={() => this.onFolderSelected({ path, name })}
                    >
                      <td>
                        <FileIcon file={doc}></FileIcon>
                      </td>
                      <td>{name}</td>
                      <td className="last-modified d-none d-sm-table-cell">
                        {moment(last_modified).fromNow()}
                      </td>
                      <td className="d-none d-sm-table-cell">
                        {modified_by && (
                          <UserDetails
                            email={modified_by.email}
                            username={modified_by.username}
                          />
                        )}
                      </td>
                      <td className="size d-none d-sm-table-cell">
                        {item_count} élément{item_count > 1 ? "s" : ""}
                      </td>
                      <td className="download"></td>
                    </tr>
                  )
                case "File":
                  return (
                    <tr key={id}>
                      <td>
                        <FileIcon file={doc}></FileIcon>
                      </td>
                      <td>{name}</td>
                      <td className="last-modified d-none d-sm-table-cell">
                        {moment(last_modified).fromNow()}
                      </td>
                      <td className="d-none d-sm-table-cell">
                        {modified_by && (
                          <UserDetails
                            email={modified_by.email}
                            username={modified_by.username}
                          />
                        )}
                      </td>
                      <td className="size d-none d-sm-table-cell">
                        {numeral(size).format("0.0 b")}
                      </td>
                      <td className="download">
                        <button
                          className="btn btn-link"
                          onClick={() => download(id, name)}
                        >
                          <i className="material-icons">get_app</i>
                        </button>
                      </td>
                    </tr>
                  )
                default:
                  return null
              }
            })}
          </tbody>
        </table>
      )
    }
    return (
      <div
        style={{
          marginBottom: "15px",
          flex: "1",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container fluid style={{ margin: "14px 0px" }}>
          <Breadcrumb crumbs={crumbs}></Breadcrumb>
        </Container>

        <div className="document">
          <div className="document__file-list">{content}</div>
          {total !== null ? (
            <div className="document__total">
              {total} élément{total > 0 ? "s" : ""}
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  onFolderSelected({ path }: any) {
    this.setState({
      currentPath: path,
    })
    this.props.history.push(`/documents/${path}`)
  }

  getFilesFromLocationPathname() {
    const [, , ...folderPath] = this.props.location.pathname.split("/") // => ["", "documents", "Tour Eiffel/Folder/path"]
    this.getFiles(folderPath)
  }

  getFiles(path: string[]) {
    this.setState({
      isLoading: true,
      documents: null,
    })

    getGedAPI()
      .getFolder(path)
      .then((folder) => {
        if (!folder) {
          this.setState({
            isLoading: false,
            error: true,
            isInitialized: true,
          })
          return
        }

        const documents = folder.content
        const currentPath = folder.path
        this.setState({
          error: false,
          documents,
          isLoading: false,
          isInitialized: true,
          currentPath,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: true,
          isInitialized: true,
        })
      })
  }
}

const UserDetails = ({ username, email }: any) => {
  const avatar = url(email, { d: "identicon" })
  return (
    <div className="user-details">
      <img
        className="user-avatar rounded-circle mr-2"
        src={avatar}
        alt="User Avatar"
      />
      {username}
    </div>
  )
}

const computeCrumbsFromPath = (path: string) => {
  let base_path = "/documents"
  const crumbs = [{ name: "Vos fichiers", path: base_path }]
  if (!path) return crumbs

  const fragments = path.split("/")
  let current = base_path
  for (const f of fragments) {
    const path = current + "/" + f
    crumbs.push({ name: f, path })
    current = path
  }
  return crumbs
}

export default withRouter(Document)
