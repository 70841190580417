/* eslint-disable */
import * as React from "react"
import { Viewer } from "photo-sphere-viewer"
import MarkersPlugin from "photo-sphere-viewer/dist/plugins/markers"

/**
 * @example <PhotoSphereViewer height="500px" width="800px" 
            src="https://axouyt279c.execute-api.eu-west-3.amazonaws.com/ccunin-dev/building/central-seine/photospheres/panorama_E86A828D_F8E3_F73A_41D8_C4D5EF3CB99F"
            items={[{"latitude": -10.55, 'longitude': -28.22}, {'latitude': 2.65, 'longitude': -56}, {'latitude': 6.47, 'longitude': -87.09}, {'latitude': 25.39, 'longitude': -168.19}, {'latitude': 6.99, 'longitude': 24.75}, {'latitude': -8.64, 'longitude': 46.46}, {'latitude': 46.06, 'longitude': 139.89}, {'latitude': 12.37, 'longitude': 145.44}, {'latitude': 34.08, 'longitude': -100.98}, {'latitude': 37.2, 'longitude': -20.4}, {'latitude': -17.45, 'longitude': 101.22}]}
          />
 */
export default function PhotoSphereViewer(props) {
  const [viewer, setViewer] = React.useState(null)
  const [markersPlugin, setMarkersPlugin] = React.useState(null)
  let {
    src,
    items,
    currentItem,
    height,
    width,
    onPositionChange,
    onMarkerSelect,
    onDoubleClick,
  } = props
  const sphereElementRef = React.createRef()

  function getMarkers() {
    return items.map((mark, index) => ({
      id: "image" + index,
      latitude: mark["latitude"],
      longitude: mark["longitude"],
      image:
        mark["type"] === "view"
          ? "/images/marker-pas.png"
          : index == currentItem
          ? "/images/marker3.png"
          : "/images/marker.png",
      width: 110,
      height: mark["type"] === "view" ? 54 : 110,
      anchor: "center",
      index: index,
      item: mark,
    }))
  }

  React.useEffect(() => {
    const spherePlayerInstance = new Viewer({
      container: sphereElementRef.current,
      panorama: src,
      navbar: ["autorotate", "zoom"],
      loadingImg: "/logo.svg",
      loadingTxt: "Chargement",
      defaultZoomLvl: 0,
      plugins: [
        [
          MarkersPlugin,
          {
            markers: getMarkers(),
          },
        ],
      ],
    })
    const markersPlugin = spherePlayerInstance.getPlugin(MarkersPlugin)
    setViewer(spherePlayerInstance)
    setMarkersPlugin(markersPlugin)

    spherePlayerInstance.on("dblclick", (e, data) => {
      if (onDoubleClick) {
        onDoubleClick(data.latitude, data.longitude)
      }
    })

    spherePlayerInstance.on("position-updated", () => {
      if (onPositionChange) {
        const position = spherePlayerInstance.getPosition()
        onPositionChange(position.lat, position.lng)
      }
    })

    if (onMarkerSelect) {
      markersPlugin.on("select-marker", (e, marker) => {
        onMarkerSelect(marker.config.index, marker.config.item)
      })
    }
  }, [])
  React.useEffect(() => {
    if (viewer) {
      markersPlugin.clearMarkers()
      viewer.setOption("loadingImg", undefined) // removes the loading image after first init.
      viewer.setOption("loadingTxt", "Chargement")
      viewer.setPanorama(src).then(() => markersPlugin.setMarkers(getMarkers()))
    }
  }, [src])
  React.useEffect(() => {
    if (markersPlugin) {
      markersPlugin.clearMarkers()
      markersPlugin.setMarkers(getMarkers())
    }
  }, [currentItem])
  return (
    <div
      className="view-container"
      ref={sphereElementRef}
      style={{ width: width, height: height }}
    />
  )
}
