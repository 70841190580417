const dateUtils = {}

const right = function (str, num) {
  return str.substring(str.length - num, str.length)
}

dateUtils.dateTick = function () {
  let date = new Date()
  let yyyy = "0000" + date.getFullYear().toString()
  let mm = "00" + (date.getMonth() + 1).toString()
  let dd = "00" + date.getDate().toString() // getMonth() is zero-based
  let HH = "00" + date.getHours().toString()
  let MM = "00" + date.getMinutes().toString()
  let SS = "00" + date.getSeconds().toString()
  let MS = "00" + date.getMilliseconds().toString()
  return (
    right(yyyy, 4) +
    right(mm, 2) +
    right(dd, 2) +
    right(HH, 2) +
    right(MM, 2) +
    right(SS, 2) +
    right(MS, 2)
  )
}

export default dateUtils
