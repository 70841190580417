/* global Autodesk */

let OPTIONS_COUNT = 0
const ACTIVE = Autodesk.Viewing.UI.Button.State.ACTIVE
const INACTIVE = Autodesk.Viewing.UI.Button.State.INACTIVE

const COMBO_BUTTON_ICON = "building"
const LAYERS = [
  {
    name: "Portes",
    icon: "cube",
    filter: {
      attribute: "Matériau de porte",
      value: "Porte - Panneau",
    },
  },
  {
    name: "Tuyauterie",
    elementIds: [3101, 9795],
    icon: "tint",
  },
  {
    name: "Chauffage",
    elementIds: [8783],
    icon: "thermometer-half",
  },
]

export class ToolbarExtension extends Autodesk.Viewing.Extension {
  load() {
    return true
  }

  onToolbarCreated(toolbar) {
    const viewer = this.viewer

    // Define vertical toolbar
    const verticalToolbar = new Autodesk.Viewing.UI.ToolBar("myCustomToolbar", {
      alignVertically: true,
      collapsible: true,
    })
    this.viewer.container.appendChild(verticalToolbar.container)

    //Main combo button
    const button = new Autodesk.Viewing.UI.ComboButton("building-combo")
    button.addClass("building-combo")
    button.setToolTip("Filtrer par réseau")
    button.icon.classList.add("fas", `fa-${COMBO_BUTTON_ICON}`)

    button.addEventListener(
      Autodesk.Viewing.UI.Button.Event.STATE_CHANGED,
      ({ state }) => {
        if (state === INACTIVE) {
          viewer.isolate()
        }
      }
    )

    // Combo button options

    LAYERS.map((l) => createOption(viewer, button, l)).forEach((option) =>
      button.addControl(option)
    )

    // SubToolbar
    const customFilters = new Autodesk.Viewing.UI.ControlGroup("custom-filters")
    customFilters.addControl(button)

    verticalToolbar.addControl(customFilters)

    const modelTools = toolbar.getControl("modelTools")

    verticalToolbar.addControl(modelTools)
  }
}

function createOption(viewer, combo, { filter, elementIds, name, icon }) {
  const id = "" + OPTIONS_COUNT++
  const option = new Autodesk.Viewing.UI.Button(`toggle-option-${id}`)

  if (elementIds) {
    option._ids = elementIds
  }

  if (filter) {
    viewer.model.search(
      filter.value,
      async (result) => {
        option._ids = result
      },
      (error) => {
        console.log(error)
      },
      filter.attribute && [filter.attribute],
      {
        includeInherited: true,
      }
    )
  }

  option.setToolTip(name)
  option.icon.classList.add("fas", "fa-" + icon)
  option.addEventListener(
    Autodesk.Viewing.UI.Button.Event.STATE_CHANGED,
    ({ state }) => {
      if (state === ACTIVE) {
        viewer.isolate(option._ids)
        combo.icon.className = "adsk-button-icon fas fa-" + icon
      }
    }
  )
  option.onClick = () => {
    const state = option.getState()
    option.setState(state === ACTIVE ? INACTIVE : ACTIVE)
  }
  return option
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  "ToolbarExtension",
  ToolbarExtension
)
