import React from "react"
import { withRouter } from "react-router-dom"
import Errors from "./Errors"
import Loader from "../components/common/Loader"
import ModelViewer from "./ModelViewer/ModelViewer"
import { Building, getMainAPI } from "../utils/api"

interface Viewer3DState {
  building?: Building
  isLoading: boolean
  error?: boolean
}

class Viewer3D extends React.Component<any, Viewer3DState> {
  constructor(props: any) {
    super(props)

    // In the case we are coming from the view 'buildings", data are already
    // available.
    const fromPreviousView = props.location.state

    if (fromPreviousView) {
      this.state = {
        building: fromPreviousView,
        isLoading: false,
      }
      return
    }

    this.state = {
      building: undefined,
      isLoading: true,
    }
  }

  componentDidMount() {
    if (this.state.building) return

    const buildingId = this.props.match.params.id
    getMainAPI()
      .getBuilding(buildingId)
      .then((building) => {
        if (!building || !building.forge_model_urn) {
          this.setState({
            isLoading: false,
            error: true,
          })
          return
        }

        this.setState({
          isLoading: false,
          building,
        })
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          error: true,
        })
      })
  }

  render() {
    const { building, error, isLoading } = this.state

    if (isLoading || !building) {
      return <Loader fullscreen></Loader>
    }

    if (error) {
      return <Errors></Errors>
    }

    return (
      <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
        <div className="w-100 h-100">
          <ModelViewer
            buildingId={building?.id}
            urn={building?.forge_model_urn!}
          ></ModelViewer>
        </div>
      </div>
    )
  }
}

export default withRouter(Viewer3D)
