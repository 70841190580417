import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"

const PageTitle = ({ title, subtitle, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0"
  )

  return (
    <Col className={classes} {...attrs}>
      <span className="text-uppercase page-subtitle d-none d-md-block">
        {subtitle}
      </span>
      <h3 className="page-title">{title}</h3>
    </Col>
  )
}

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,
}

export default PageTitle
