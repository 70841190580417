import { FunctionComponent, useEffect, useState } from "react"
import { Badge, Button, Card, ListGroup } from "react-bootstrap"
import moment from "moment"
import { Equipment, EquipmentIntervention, getMainAPI } from "../../utils/api"
import Loader from "../../components/common/Loader"
import GedResource from "../GedResource/GedResource"

interface ModelInfoProps {
  buildingId: string
  equipmentId?: string
  close: () => void
}

const ModelInfo: FunctionComponent<ModelInfoProps> = ({
  buildingId,
  equipmentId,
  close,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [equipment, setEquipment] = useState<Equipment>()

  useEffect(() => {
    if (!equipmentId) {
      return
    }
    setIsLoading(true)
    getMainAPI()
      .getEquipment(buildingId, equipmentId)
      .then((equipment) => {
        setEquipment(equipment)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [buildingId, equipmentId])

  const content = isLoading ? (
    <Loader fullscreen />
  ) : !equipment ? (
    <>
      <Card.Title>Objet inconnu</Card.Title>
      <div className="sections">
        <div className="section">
          L'objet sélectionné n'est pas référencé. Veuillez en sélectionner un
          autre.
        </div>
      </div>
    </>
  ) : (
    <>
      <Card.Title>
        <h6>{equipment.label}</h6>
      </Card.Title>
      <Card.Subtitle>{equipment.equipment_id}</Card.Subtitle>
      <div className="sections">
        <div className="section">
          <ListGroup variant="flush">
            {[
              {
                label: "Famille",
                value: equipment.family,
              },
              {
                label: "Équipement",
                value: equipment.familyLabel,
              },
              {
                label: "Niveau",
                value: equipment.zone,
              },
              {
                label: "Local",
                value: equipment.space,
              },
              {
                label: "Équipe",
                value: equipment.workTeam,
              },
            ].map((e, key) => (
              <div className="pb-1">
                <span className="text-muted mr-1">{e.label}:</span>
                <span>{e.value}</span>
              </div>
            ))}
          </ListGroup>
        </div>
        <div className="section">
          <div className="section-title">Documentation</div>
          <div className="ged-resources">
            {equipment.documents?.length ? (
              equipment.documents.map((doc, key) => {
                return <GedResource key={key} resource={doc} />
              })
            ) : (
              <span>Aucun document associé</span>
            )}
          </div>
        </div>
        {Array.from(
          equipment.interventions.reduce(
            (set, { activityLabel }) => set.add(activityLabel),
            new Set<string | undefined>()
          )
        ).map((type) => {
          return (
            <div className="section">
              <div className="section-title">
                Interventions - {type || "Autre"}
              </div>
              <ModelInfoInterventions
                interventions={equipment.interventions.filter(
                  ({ activityLabel }) => activityLabel === type
                )}
              />
            </div>
          )
        })}
      </div>
    </>
  )

  return (
    <Card className={"model-info"}>
      <Card.Body>
        <Button className="close-button" variant="light" onClick={close}>
          <i className="material-icons">close</i>
        </Button>
        {content}
      </Card.Body>
    </Card>
  )
}

const ModelInfoInterventions: FunctionComponent<{
  interventions?: EquipmentIntervention[]
}> = ({ interventions }) => {
  if (!interventions?.length) return <span>Aucune intervention à venir</span>

  return (
    <div>
      <div className="interventions">
        {interventions
          .sort((a, b) => {
            return (
              a.realizedBeginDate || a.firstPlanificationBeginDate
            ).localeCompare(
              b.realizedBeginDate || b.firstPlanificationBeginDate
            )
          })
          .map((intervention, key) => (
            <Intervention key={key} intervention={intervention} />
          ))}
      </div>
    </div>
  )
}

const InterventionStatus: FunctionComponent<{
  status: "TE" | "PR" | "AR" | "EC"
}> = ({ status }) => {
  const s = STATUS_MAP[status] || DEFAULT_STATUS
  return <Badge variant={s.variant}>{s.label}</Badge>
}

const STATUS_MAP = {
  TE: { label: "Terminé", variant: "success" },
  PR: { label: "Programmé", variant: "info" },
  AR: { label: "Archivé", variant: "secondary" },
  EC: { label: "En cours", variant: "warning" },
}

const DEFAULT_STATUS = { label: "Pas de status", variant: "danger" }

const Intervention: FunctionComponent<{
  intervention: EquipmentIntervention
}> = ({ intervention }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div className="intervention" onClick={() => setOpen(!open)}>
        <div className={`expand ${open ? "" : "collapsed"}`}>
          <i className="material-icons">expand_more</i>
        </div>
        <div className="date">
          {moment(
            intervention.realizedBeginDate ||
              intervention.firstPlanificationBeginDate
          ).format("ll")}
        </div>
        <div className="label">{intervention.domainLabel}</div>
        <div className="code">
          <Badge variant="dark">Réf {intervention.code}</Badge>
        </div>
        <div className="status">
          <InterventionStatus status={intervention.status as any} />
        </div>
      </div>
      {open ? (
        <div className="intervention-details">
          {[
            ["Domaine", intervention.domainLabel],
            ["Intervention", intervention.label],
            ["Référence", intervention.code],
            [
              "Date de planification",
              moment(intervention.firstPlanificationBeginDate).format("ll"),
            ],
            [
              "Date de réalisation",
              intervention.realizedBeginDate
                ? moment(intervention.realizedBeginDate).format("ll")
                : "-",
            ],
            ["Status", intervention.status],
            ["Intervenants", intervention.workTeam],
          ].map(([label, value]) => (
            <div className="pb-1">
              <span className="text-muted mr-1">{label}:</span>
              <span>{value}</span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default ModelInfo
