import React, { Component } from "react"
import { LeafletEdition } from "./LeafletEdition"
import { Dropdown } from "react-bootstrap"

export class LeafletUI extends Component {
  constructor(props) {
    super(props)

    this.state = {
      floorDropdownOpen: false,
    }
  }

  render() {
    return (
      <>
        <LeafletEdition
          editMode={this.props.editMode}
          handleClickEditCancel={this.props.handleClickEditCancel}
          handleClickEditSave={this.props.handleClickEditSave}
          handleClickEnableEdit={this.props.handleClickEnableEdit}
          markerSelected={this.props.markerSelected}
          editEnabled={this.props.editEnabled}
        />
        {this.props.editMode || this.props.markerSelected ? null : (
          <Dropdown
            open={this.state.floorDropdownOpen}
            className={"map-edit-floor-dropdown"}
            drop="up"
          >
            <Dropdown.Toggle>Niveau</Dropdown.Toggle>
            <Dropdown.Menu>
              {this.props.floors.map((m) => (
                <Dropdown.Item
                  key={m.name}
                  onClick={() => {
                    this.props.handleFloorDropdownChange(m.name)
                  }}
                >
                  {m.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    )
  }
}
