const imageUtils = {}

imageUtils.getImageMeta = (url) => {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => resolve(img)
    img.onerror = () => reject()
    img.src = url
  })
}

export default imageUtils
